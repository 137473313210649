@import "~factor/src/scss/variables";

//colors
$titan-white: #EBEEFF;
$alto-2: #d1d1d1;
$mercury: #e8e8e8;
$dove-gray-1: #696969;
$mine-shaft-1: #242424;
$table-hover: #d8d8d8;
$silver-chalice: #a2a2a2;
$dark-gray: #cbcbcb;

$manhattan: #f7ce85;

$titan-white: #EBEEFF;
$dodger-blue-1: #3399FF;
$conflower-blue: #6075ff;
$burgundy: #9b3434;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1200;
$xlg: 1280;
